import htmx from 'htmx.org';
// import hyperscript from 'hyperscript.org';

window.htmx = htmx
// window._hyperscript = hyperscript
// window._hyperscript.browserInit()

import './bootstrap';
import '../sass/app.scss';
// import { Calendar } from '@fullcalendar/core';
// // import '@fullcalendar/common';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
// import interactionPlugin from '@fullcalendar/interaction';
// import jaLocale from '@fullcalendar/core/locales/ja'; 
// import nbLocale from '@fullcalendar/core/locales/nb'; 

// window.Calendar = Calendar;
// window.dayGridPlugin = dayGridPlugin;
// window.timeGridPlugin = timeGridPlugin;
// window.listPlugin = listPlugin;
// window.interactionPlugin = interactionPlugin;
// window.jaLocale = jaLocale;
// window.nbLocale = nbLocale;

import * as easepick from '@easepick/bundle';
window.easepick = easepick;
